import React, {useContext, useEffect, useState} from "react";
import {Form, FormLabel} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import ValidationContext from "./ValidationContext";
import HelpTextAccordion from "./HelpTextAccordion";

interface Props {
    fieldName: string,
    initialValue: boolean,
    onChange: (value: boolean) => void,
    disabled?: boolean,
    required?: boolean
    children?: React.ReactElement | React.ReactElement[]
}

const ToggleInput = React.forwardRef<HTMLInputElement, Props>(({
            fieldName,
            initialValue,
            onChange,
            disabled,
            children,
            required
        }, forwardRef) => {
    const {t} = useTranslation();

    const validation = useContext(ValidationContext);
    const {validated, validationCounter} = validation;

    const [value, setValue] = useState<boolean>(initialValue);
    const hasTitle = t(`${fieldName}.title`) !== `${fieldName}.title`;
    const [changedAfterValidation, setChangedAfterValidation] = useState<boolean>(false);
    const invalid = !value && required;
    const validatedEffective = validated && !changedAfterValidation;

    useEffect(() => {
        if (changedAfterValidation) {
            setChangedAfterValidation(false);
        }
    }, [validationCounter]);

    return (
        <Form.Group className={"toggle-group"}>
            <FormLabel htmlFor={fieldName} className="position-relative">
                <div className={`toggle ${validatedEffective ? "was-validated" : 'was-not-validated'} ${invalid ? "is-invalid" : ''}`}>
                    <FormCheckInput
                        className={`${validated ? "changed" : ""}`}
                        ref={forwardRef}
                        data-testid={fieldName}
                        id={fieldName}
                        checked={value}
                        disabled={disabled}
                        required={required}
                        isValid={!invalid}
                        isInvalid={invalid}
                        onChange={(e:any) => {
                            const value = (e.target as any).checked;
                            if (validated) {
                                setChangedAfterValidation(true);
                            }
                            setValue(value);
                            onChange(value);
                        }}/>
                    <span>{/*Empty span to target pseudo element for custom tick mark as IOS safari rendering engine doesn't like pseudo elements in input elements*/}</span>
                </div>
                <div className={"toggle-content error-label"}>
                    {hasTitle ? <h2>
                        {t(`${fieldName}.title`)}
                    </h2> : <></>}
                    {t(`${fieldName}.label`) !== `${fieldName}.label` ? <span>
                        {t(`${fieldName}.label`)}
                    </span> : <></>}
                </div>
                <Form.Control.Feedback type={"invalid"}>
                    {t(`${fieldName}.invalid`)}
                </Form.Control.Feedback>
            </FormLabel>
            <HelpTextAccordion fieldName={fieldName} />
            {value && <div className={`toggle-content when-selected ${validatedEffective ? "was-validated" : 'was-not-validated'}`}>
                {t(`${fieldName}.subTitle`) !== `${fieldName}.subTitle` ? <h2>{t(`${fieldName}.subTitle`)}</h2> : <></>}
                {t(`${fieldName}.explanation`) !== `${fieldName}.explanation` ? <p>{t(`${fieldName}.explanation`)}</p> : <></>}
                <ValidationContext.Provider value={{...validation, validationCounter}}>
                    {children}
                </ValidationContext.Provider>
            </div>}
        </Form.Group>
    );
});

export default ToggleInput;
