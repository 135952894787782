import React, {ReactNode, useEffect, useRef, useState} from "react";
import {components, MenuListProps} from 'react-select';
import {Virtuoso} from 'react-virtuoso';
import {OptionProps} from "react-select/dist/declarations/src/components/Option";

// see: https://github.com/JedWatson/react-select/issues/3128
// terribly slow width default components

const InnerItem = React.memo(({children}: {children: ReactNode}) => {
    return <>{children}</>;
});

const NUMBER_ITEMS_VISIBLE = 6;
const ITEM_HEIGHT = 80;

const getListHeight = (length: number) => {
    return length < NUMBER_ITEMS_VISIBLE ?
        length * ITEM_HEIGHT :
        NUMBER_ITEMS_VISIBLE * ITEM_HEIGHT;
};

export const CustomMenuList = ({options, children, getValue, hasValue, focusedOption, ...rest}: MenuListProps) => {
    const virtuosoRef = useRef(null);
    const [initialFocus, setInitialFocus] = useState(false);
    const [option] = getValue();

    useEffect(() => {
        let wasSetTrue = false;
        let current = virtuosoRef?.current;
        if (current) {
            let selectedOptionIndex = 0;
            // scroll to the selected option
            if (option && !initialFocus) {
                selectedOptionIndex = options.findIndex((item) => (item as any).value === (option as any).value);
                wasSetTrue = true;
                //scroll to the focused option
            } else if (focusedOption) {
                selectedOptionIndex = options.findIndex((item) => (item as any).value === (focusedOption as any).value);
            }
            (current as any).scrollToIndex({
                index: selectedOptionIndex,
                align: "center",
                behavior: "auto",
            });
        }
        return () => {
            // Component update to track that we can now scroll to whatever receives focus as opposed to the currently selected option
            if (wasSetTrue) {
                setInitialFocus(true);
            }
        }
    }, [children, virtuosoRef, options, option, getValue, focusedOption, initialFocus]);

    return Array.isArray(children) ? (
        <Virtuoso
            ref={virtuosoRef}
            overscan={{main: 12, reverse: 12}}
            style={{height: `${getListHeight(children.length)}px`}}
            totalCount={children.length}
            itemContent={(index) => <InnerItem children={children[index]}/>}
        />
    ) : (
        <div>{children}</div>
    );
};

export const CustomOption = ({children, ...props}: OptionProps) => {
    // Remove the niceties for mouseover and mousemove to optimize for large lists
    // eslint-disable-next-line no-unused-vars
    const {onMouseMove, onMouseOver, ...rest} = props.innerProps;
    const newProps = {...props, innerProps: rest};

    // Addition for industry indents:
    let additionalStyles = "";
    if (props.data) {
        if ((props.data as any).value) {
            additionalStyles = "value-length-" + (props.data as any).value?.length;
        }
    }

    return (
        <components.Option {...newProps}
                className={`custom-option ${additionalStyles}`}>
            {children}
        </components.Option>
    );
}

const optimizeSelectComponents = {
    MenuList: CustomMenuList,
    Option: CustomOption,
};

export default optimizeSelectComponents;
