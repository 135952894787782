import {Validation} from "./ValidationContext";
import React, {useState} from "react";
import {Feedback, resolveErrorsAsync} from "../utils/form-util";


export function useValidation(includeLabel = true): Validation {
    const [validationCounter, setValidationCounter] = useState<number>(0);
    const [validationFeedback, setValidationFeedback] = useState<Feedback[]>([]);
    const validated = validationCounter > 0;
    const validate = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;
        const validateResult = form.checkValidity();
        resolveErrorsAsync(form, setValidationFeedback, includeLabel);
        setValidationCounter(c => c+1);
        return validateResult;
    };

    return {
        validated,
        validationCounter,
        validationFeedback,
        validate
    };
}
