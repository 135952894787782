import {useContext} from "react";
import CodesContext from "./CodesContext";
import {labels, ValueLabel} from "../types/ValueLabel";
import {I18n} from "../types/I18n";
import i18next from 'i18next';

export interface Industries {
    industries: ValueLabel<string, I18n>[]
    options: ValueLabel<string>[]
}

export function useIndustries(): Industries {
    const languageCode = i18next.language;
    const {industries} = useContext(CodesContext);
    const options = labels(industries?.filter(i => i.value.length === 5) || [], languageCode,
        (label, value) => `${value} ${label}`);

    return {
        industries: industries || [],
        options
    };
}
