import React, {useEffect, useMemo, useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {Button, Col, Container, Form, FormLabel, InputGroup, Row} from "react-bootstrap";
import Select, {createFilter} from "react-select";
import {ValueLabel} from "../../types/ValueLabel";
import optimizedReactSelect from ".././OptimizedReactSelect";
import {useIndustries} from "../useIndustries";
import axios from "axios";
import {RecommendedEarningsResponse} from "../../types/yttari/RecommendedEarnings";
import i18n from "i18next";
import {useCodeList} from "../useCodeList";
import {I18n} from "../../types/I18n";
import CodesContext from "../CodesContext";
import ValidationContext from "../ValidationContext";
import {useValidation} from "../useValidation";
import ValidationFeedback from "../ValidationFeedback";
import {trackEvent} from "../../utils/piwik-util";


const YttariCalculator = () => {
    const industries = useCodeList<ValueLabel<string, I18n>>("industry");
    return <CodesContext.Provider value={{industries}}>
        <YttariView/>
    </CodesContext.Provider>;
}


const YttariView: React.FC = () => {
    const {t, i18n: {language}} = useTranslation();
    const {options} = useIndustries();

    const [selectedIndustry, setSelectedIndustry] = useState<ValueLabel<string> | null>(null);
    const [estimatedRevenue, setEstimatedRevenue] = useState<string>("");
    const [recommendedEarnings, setRecommendedEarnings] = useState<RecommendedEarningsResponse | null>(null);
    const [responseError, setResponseError] = useState<string | null>(null);
    const [requestedIndustryCode, setRequestedIndustryCode] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (selectedIndustry) {
            setSelectedIndustry(options.find(o => o.value === selectedIndustry.value) || null);
        }
    }, [language]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setRecommendedEarnings(null);
        setRequestedIndustryCode(undefined);
        setResponseError(null);

        axios.post<RecommendedEarningsResponse>(`/api/calculate-earnings?language=${language}`, {
            industry: selectedIndustry?.value,
            estimatedRevenue: parseInt(estimatedRevenue.trim().replace(/\s/g, ''))
        }).then(response => {
            if (response.data) {
                setRecommendedEarnings(response.data);
                setRequestedIndustryCode(selectedIndustry?.value);
            }
        }).catch(error => {
            setResponseError(error.response?.data?.message || t('errorUnknown'));
        })
    }

    return (
        <>
            <Container className="calculator-container content-container">
                <h1>{t('yttari.calculatorTitle')}</h1>
                <p className="ingress">{t('yttari.ingress')}</p>
            </Container>

            <div className="form-container py-4">
                <Container className="content-container">
                    <Row>
                        <Col>
                            <EarningsForm selectedIndustry={selectedIndustry}
                                          setSelectedIndustry={setSelectedIndustry}
                                          estimatedRevenue={estimatedRevenue}
                                          setEstimatedRevenue={setEstimatedRevenue}
                                          onSubmit={onSubmit}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col id="results-region" role="region" aria-live="polite" md={12} className="mt-4">
                            {responseError && (
                                <div>
                                    <h2>{responseError}</h2>
                                </div>
                            )}
                            <RecommendedEarnings recommendedEarnings={recommendedEarnings} selectedIndustryCode={requestedIndustryCode}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

interface EarningsFormProps {
    selectedIndustry: ValueLabel<string> | null,
    setSelectedIndustry: (industry: ValueLabel<string> | null) => void,
    estimatedRevenue: string,
    setEstimatedRevenue: (estimatedRevenue: string) => void,
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

const EarningsForm = ({
                          selectedIndustry,
                          setSelectedIndustry,
                          estimatedRevenue,
                          setEstimatedRevenue,
                          onSubmit
                      }: EarningsFormProps): JSX.Element => {
    const {t} = useTranslation();
    const {options} = useIndustries();
    const validation = useValidation();

    const [revenueChanged, setRevenueChanged] = useState<boolean>(false);
    const [revenueValid, setRevenueValid] = useState<boolean>(true);

    const industryValid = useMemo(() => Boolean(selectedIndustry?.value), [selectedIndustry]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (validation.validate(event)) {
            onSubmit(event);
        }
    }

    const onRevenueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setRevenueChanged(true);
        setRevenueValid(!!value && /[0-9 ]/.test(value))
        setEstimatedRevenue(value.trim().replace(/\s/g, ''));
    }

    let selectIndustryClasses = "select-industry";
    if (!industryValid) {
        selectIndustryClasses += " invalid";
    }

    return <ValidationContext.Provider value={validation}>
        <Form noValidate validated={validation.validated} onSubmit={handleSubmit} className="calculator-form">
            <ValidationFeedback feedback={validation.validationFeedback} />
            <Row>
                <Col md={12}>
                    <Form.Group className="industry-select">
                        <Form.Label htmlFor="industry">
                            <h2 className="error-label">{t('yttari.selectIndustry.title')}</h2>
                            <p>
                                <Trans i18nKey="yttari.selectIndustry.explanation"
                                       components={{
                                           Link: <a href={t('yttari.selectIndustry.tolUrl')}>stat.fi</a>
                                       }}
                                />
                            </p>
                        </Form.Label>
                        <Select className={selectIndustryClasses}
                                classNamePrefix="select-industry"
                                inputId="industry"
                                isMulti={false}
                                isSearchable={true}
                                isClearable={true}
                                options={options}
                                value={selectedIndustry}
                                onChange={setSelectedIndustry}
                                placeholder={t('yttari.selectIndustry.placeholder')}
                                noOptionsMessage={() => t('yttari.selectIndustry.noOptions')}
                                // performance issue resolving options:
                                captureMenuScroll={false}
                                components={optimizedReactSelect as any}
                                filterOption={createFilter({ignoreAccents: false, matchFrom: 'any'})}
                        />
                        <InputGroup hasValidation>
                            {/* Workaround to make react-select part of html5 validation */}
                            <Form.Control value={selectedIndustry?.value || ""}
                                          data-test-id="industry"
                                          className="hidden"
                                          required
                                          aria-hidden="true"
                                          onChange={() => {}}
                                          tabIndex={-1}
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('yttari.selectIndustry.invalid')}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Form.Group className={"value-input"}>
                    <Col md={12}>
                        <FormLabel htmlFor={"estimated-revenue"}>
                            <h2 className="error-label">{t('yttari.revenue.title')}</h2>
                            <p>{t('yttari.revenue.explanation')}</p>
                        </FormLabel>
                    </Col>
                    <Col sm={12}>
                        <InputGroup hasValidation className={`${validation.validated ? "was-validated" : "was-not-validated"}`}>
                            <Form.Control id="estimated-revenue"
                                          className={`value-input ${revenueChanged ? "changed" : ""} ${revenueValid ? "" : "invalid is-invalid"}`}
                                          value={estimatedRevenue}
                                          onChange={onRevenueChange}
                                          pattern="[1-9][0-9]{0,9}"
                                          maxLength={10}
                                          placeholder=""
                                          required
                            />
                            <InputGroup.Append aria-hidden="true">
                                <InputGroup.Text>{t('yttari.eurYear')}</InputGroup.Text>
                            </InputGroup.Append>
                            <Form.Control.Feedback type="invalid">
                                {t('yttari.revenue.invalid')}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                </Form.Group>
            </Row>
            <Row>
                <Col sm={6} className="mt-3">
                    <Button type="submit" aria-controls="results-region" onClick={e => {
                        trackEvent('Button', t('yttari.calculate'));
                    }}>
                        {t('yttari.calculate')}
                    </Button>
                </Col>
            </Row>
        </Form>
    </ValidationContext.Provider>;
};

interface RecommendedEarningsProps {
    recommendedEarnings: RecommendedEarningsResponse | null,
    selectedIndustryCode: string | undefined
}

const RecommendedEarnings = ({recommendedEarnings, selectedIndustryCode}: RecommendedEarningsProps): JSX.Element | null => {
    const {t} = useTranslation();

    if (!recommendedEarnings) {
        return null;
    }

    const formatNumber = (number: number, precision: number = 0) => {
        return new Intl.NumberFormat('fi-FI', {maximumFractionDigits: precision}).format(number);
    }

    const forOccupation = recommendedEarnings.medianEarningsOccupation;

    return (
        <div className="results-container">
            <h2>{t('yttari.result.title')}</h2>
            <div className="result-value mb-3">
                {t('yttari.result.recommended', {
                    recommended: formatNumber(recommendedEarnings.recommendedEarnings)
                })}
            </div>
            <p>
                <Trans i18nKey="yttari.result.earnings.leeway"
                       components={{strong: <strong>bounds</strong>}}
                       values={{
                           lower: formatNumber(recommendedEarnings.earningsLowerLimit),
                           upper: formatNumber(recommendedEarnings.earningsUpperLimit)
                       }}
                />
                {recommendedEarnings.infos.includes('AUTYOTUL_009') ? (
                    <>{' '}{t('yttari.result.earnings.lowerLimitUnder')}</>
                ) : null}
                {recommendedEarnings.infos.includes('AUTYOTUL_008') ? (
                    <>{' '}{t('yttari.result.earnings.upperLimitOver')}</>
                ) : null}
                {' '}{t('yttari.result.earnings.exception')}
            </p>
            <div className={"calculationInfo mt-5"}>
                <h3>{t('yttari.result.calculationInfo.title')}</h3>
                <ul>
                    <li>
                        <Trans
                            i18nKey={`yttari.result.calculationInfo.medianRevenue.${recommendedEarnings.medianRevenueIndustry?.value === 'KAIK' ? 'allIndustries' : 'forIndustry'}`}
                            values={{
                                medianRevenue: formatNumber(recommendedEarnings.industryMedianRevenue),
                                industryCode: recommendedEarnings.medianRevenueIndustry?.value,
                                industryName: recommendedEarnings.medianRevenueIndustry?.label[i18n.language],
                            }}
                        />
                    </li>
                    <li>
                        <Trans
                            i18nKey={`yttari.result.calculationInfo.medianEarnings.${recommendedEarnings.medianEarningsIndustry?.value === 'KAIK' ? 'allIndustries'
                                : (forOccupation ? 'forOccupation' : 'forIndustry')}`}
                            values={{
                                medianEarnings: formatNumber(recommendedEarnings.industryMedianEarnings),
                                occupationCode: forOccupation?.value,
                                occupationName: forOccupation?.label[i18n.language],
                                industryCode: recommendedEarnings.medianEarningsIndustry?.value,
                                industryName: recommendedEarnings.medianEarningsIndustry?.label[i18n.language],
                            }}
                        />
                    </li>
                    <li>
                        {t('yttari.result.calculationInfo.footer')}
                    </li>
                </ul>
            </div>
            <hr className="divider"/>
            <p>
                <Trans i18nKey="yttari.result.extraInfo.paragraph1"
                       components={{
                           Link: <a href={t('yttari.result.extraInfo.contactsUrl')}>tyoelake.fi/contacts</a>
                       }}
                />
            </p>
            <p>
                <Trans i18nKey="yttari.result.extraInfo.paragraph2"
                       components={{
                           Link: <a href={t('yttari.result.extraInfo.legalUrl')}>etk.fi</a>
                       }}
                />
            </p>
            <p>
                <Trans i18nKey="yttari.result.extraInfo.paragraph3"
                       components={{
                           Link: <a href={t('yttari.result.extraInfo.yelInfoUrl')}>tyoelake.fi/pensions</a>
                       }}
                />
            </p>
        </div>
    );
}

export default YttariCalculator;
