import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {parseQuery} from "../../utils/queryUtils";
import {Button, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import CodesContext from "../CodesContext";
import {ElakePerhevapaanAjaltaResult} from "../../types/ElakePerhevapaanAjaltaResult";
import {ElakePerhevapaanAjaltaModel} from "../../types/ElakePerhevapaanAjaltaModel";
import {Feedback, scrollTo} from "../../utils/form-util";
import {useCodeList} from "../useCodeList";
import {PensionEstimate, YearConstants, yearConstantValue as yearConstantValueOriginal, pensionEstimateValue} from "../../types/Codes";
import {useValidation} from "../useValidation";
import ValidationContext, {Validation} from "../ValidationContext";
import ValidationFeedback from "../ValidationFeedback";
import {trackEvent} from "../../utils/piwik-util";
import NumberInput from "../NumberInput";
import SelectInput from "../SelectInput";
import {rangeClosed} from "../../utils/array-utils";
import {calculate, calculateChartData, CalculationContext, ChartContext, ChartData} from "../../calculation/ElakePerhevapaanAjaltaCalculation";
import CalculationResult from "../CalculationResult";
import { euroSumOrZero, toFiLocaleTwoDecimals } from "../../utils/numberUtils";
import {ChartOptions, defaults} from "chart.js";
import {Line} from "react-chartjs-2"

const ElakePerhevapaanAjaltaCalculator: React.FC = () => {
    const yearConstants = useCodeList<YearConstants>("year-constants");
    const pensionEstimate = useCodeList<PensionEstimate>("pension-estimate");
    return <CodesContext.Provider value={{yearConstants, pensionEstimate}}>
        <ElakePerhevapaanAjaltaView/>
    </CodesContext.Provider>
}

const ElakePerhevapaanAjaltaView: React.FC = () => {
    const {t} = useTranslation();

    const {yearConstants, pensionEstimate} = useContext(CodesContext);
    const [model, setModel] = useState<ElakePerhevapaanAjaltaModel | null>(null)
    const [result, setResult] = useState<ElakePerhevapaanAjaltaResult | null>(null);
    const [resultChart, setResultChart] = useState<ChartData | null>(null);
    const [missingCodeErrors, setMissingCodeErrors] = useState<Feedback[]>([]);
    const resultsSection = useRef(null);
    const {search} = useLocation();
    const query = parseQuery(search);
    // Allow specifying year for e.g. repeatable Robot testing and manual testing:
    const year = parseInt(query.year) || new Date().getFullYear();
    const validation = useValidation();

    const yearConstantValue = useCallback((constant: keyof YearConstants) => yearConstantValueOriginal(year, constant, yearConstants), [year, yearConstants]);

    const calculationContext = useMemo(() => {
        if (!model) {
            return null;
        }
        let deductionCurrentYear = yearConstantValue("vakuutusmaksuvahennysKuluvavuosi");
        let pensionReasonFactor = yearConstantValue("elakkeenPerusteKerroin");
        let pensionReasonMinimumAllowance = yearConstantValue("elakkeenPerusteMinimipaivarahasta")
        let accruePercentage = yearConstantValue("karttumisProsentti");
        let lifetimeFactor = pensionEstimateValue(model.birthYear || 0, "ek", pensionEstimate);
        return {
            deductionCurrentYear, pensionReasonFactor, pensionReasonMinimumAllowance, accruePercentage, lifetimeFactor
        } as CalculationContext;
    }, [yearConstants, pensionEstimate, model]);

    useEffect(() => {
        if (model) {
            if (calculationContext) {
                setResult(calculate(model, calculationContext));
                let chartdata = calculateChartData(model,calculationContext, {translate: t} as ChartContext);
                setResultChart(chartdata);
                scrollTo(resultsSection);
            } else {
                setMissingCodeErrors([{text: t('errorFeedback.missingCodeValuesForThisYear')}]);
            }
        } else {
            setResult(null);
        }
    }, [model, calculationContext, t]);

    return <>
        <Container className="calculator-container content-container">
            <h1>{t('elake-perhevapaan-ajalta-laskuri.calculatorTitle')}</h1>
            <p className="ingress">
                {t('elake-perhevapaan-ajalta-laskuri.ingress')}
            </p>
        </Container>
        <div className="form-container py-4">
            <Container className="content-container">
                <Row>
                    <Col md={12}>
                        <ElakePerhevapaanAjaltaForm year={year}
                                                    onClear={() => setResult(null)}
                                                    onSubmit={(event, model) => {
                                                        event.preventDefault();
                                                        setModel(model);
                                                    }} validation={validation} />
                    </Col>
                </Row>
                <Row>
                    <ValidationFeedback className={"mt-5"} titleKey={`errorFeedback.internalErrorTitle`} feedback={missingCodeErrors} />
                    {!missingCodeErrors.length && !validation.validationFeedback.length && <Col id="results-region" role="region" aria-live="polite" md={12} className="mt-4" ref={resultsSection}>
                        {result && <div className="results-container">
                            <h2>{t('elake-perhevapaan-ajalta-laskuri.results.title')}</h2>
                            {!!result.parentalPensionOverall && <>
                                <CalculationResult className="mb-0" fieldName="elake-perhevapaan-ajalta-laskuri.results.accruementDuringFamilyLeave">
                                    <>{euroSumOrZero(result.parentalPensionOverall)}</>
                                </CalculationResult>
                                {(!!result.pensionAllowance || !!result.pensionHomeCare) && <div className="mb-3">
                                    {result.pensionAllowance !== undefined &&
                                        <div>{t('elake-perhevapaan-ajalta-laskuri.results.accruementDuringFamilyLeave.duringAllowancePeriod', {euros: toFiLocaleTwoDecimals(result.pensionAllowance)})}</div>}
                                    {result.pensionHomeCare !== undefined &&
                                        <div>{t('elake-perhevapaan-ajalta-laskuri.results.accruementDuringFamilyLeave.duringHomeCarePeriod', {euros: toFiLocaleTwoDecimals(result.pensionHomeCare)})}</div>}
                                </div>}
                            </>}
                            {!!result.pensionSalary && <>
                                <CalculationResult fieldName="elake-perhevapaan-ajalta-laskuri.results.accruementPotentialSalary">
                                    <>{euroSumOrZero(result.pensionSalary)}</>
                                </CalculationResult>
                            </>}
                            {!!resultChart && <div className="chart-container">
                                <ElakePerhevapaanAjaltaChart data={resultChart} />
                            </div>}
                            <div className="mb-2">
                                {t('elake-perhevapaan-ajalta-laskuri.results.explanation')}
                            </div>
                        </div>}
                    </Col>}
                </Row>
            </Container>
        </div>
    </>;
}

interface FormProps {
    year: number
    onSubmit: (event: React.FormEvent<HTMLFormElement>, model: ElakePerhevapaanAjaltaModel) => void
    onClear: () => void,
    validation: Validation
}

const ElakePerhevapaanAjaltaForm: React.FC<FormProps> = ({year, onSubmit, onClear, validation}) => {
    const {t} = useTranslation();
    const topSection = useRef(null);

    const [formModel, setFormModel] = useState<ElakePerhevapaanAjaltaModel>({})

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const validateResult = validation.validate(event);
        if (validateResult) {
            onSubmit(event, formModel);
        } else {
            onClear();
            scrollTo(topSection);
        }
    }

    const birthYearOptions = rangeClosed(1973, year - 18)
    const allowanceDurationOptions = [
        ...rangeClosed(0, 10).map(o => ({value: o, label: o + ""})),
        ...rangeClosed(11, 13).map(o => ({value: o, label: `${o} ${t('elake-perhevapaan-ajalta-laskuri.allowanceDuration.specialCase')}`}))
    ];
    const homeCareDurationOptions = rangeClosed(0, 30);

    return <ValidationContext.Provider value={validation}>
        <div ref={topSection}></div>
        <Form noValidate validated={validation.validated}
              onSubmit={handleSubmit} className="calculator-form" id={"perhevapaa"}>
            <ValidationFeedback feedback={validation.validationFeedback} />
            <Row>
                <Col md={12}>
                    <InputGroup hasValidation className={`${validation.validated ? "was-validated" : "was-not-validated"}`}>
                        <SelectInput fieldName="elake-perhevapaan-ajalta-laskuri.birthYear"
                                     required
                                     options={birthYearOptions}
                                     value={formModel.birthYear}
                                     setValue={(birthYear?: number) => setFormModel(fm => ({...fm, birthYear}))} />

                        <NumberInput fieldName="elake-perhevapaan-ajalta-laskuri.salaryPreviousYear"
                                     initialValue={formModel.salaryPreviousYear}
                                     required min={0} max={999999999} onlyInts
                                     onValidValueChange={salaryPreviousYear => setFormModel(fm => ({...fm, salaryPreviousYear}))} />

                        <SelectInput fieldName="elake-perhevapaan-ajalta-laskuri.allowanceDuration"
                                     options={allowanceDurationOptions}
                                     value={formModel.allowanceDuration}
                                     setValue={(allowanceDuration?: number) => setFormModel(fm => ({...fm, allowanceDuration}))} />

                        <SelectInput fieldName="elake-perhevapaan-ajalta-laskuri.homeCareDuration"
                                     options={homeCareDurationOptions}
                                     value={formModel.homeCareDuration}
                                     setValue={(homeCareDuration?: number) => setFormModel(fm => ({...fm, homeCareDuration}))} />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={6} className="mt-3">
                    <Button type="submit" aria-controls="results-region"
                            onClick={e => {
                                trackEvent('Button', t('elake-perhevapaan-ajalta-laskuri.calculate'));
                            }}>
                        {t('elake-perhevapaan-ajalta-laskuri.calculate')}
                    </Button>
                </Col>
            </Row>
        </Form>
    </ValidationContext.Provider>
}

interface ChartProps {
    data: ChartData
}

const ElakePerhevapaanAjaltaChart: React.FC<ChartProps> = ({data}) => {
    const {t} = useTranslation();

    defaults.font.size = 14;
    defaults.color = "#4e5965";
    const options = useMemo(() => ({
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 20
            }
        },
        elements: {
            point: {
                radius: 3
            }
        },
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    text: t('elake-perhevapaan-ajalta-laskuri.chart.month')
                },
                ticks: {
                    beginAtZero: true
                }
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: t('elake-perhevapaan-ajalta-laskuri.chart.unit')
                },
                ticks: {
                    beginAtZero: true
                }
            }
        },
        plugins: {
            legend: {
                position: "bottom",
                align: "center",
                useLineStyle: true,
                onClick: () => {},
                labels: {
                    boxHeight: 0,
                    fontSize: 16,
                    padding: 15
                }
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    title: (context: any) => {
                        return context[0].dataset.label;
                    },
                    label: (context: any) => {
                        return t('elake-perhevapaan-ajalta-laskuri.chart.tooltipLabel', {month: context.parsed.x, euros: toFiLocaleTwoDecimals(context.parsed.y)});
                    }
                }
            },
            datalabels: {
                formatter: (value: any, context: any) => t('elake-perhevapaan-ajalta-laskuri.chart.datalabel', {euros: euroSumOrZero(value)}),
                display: (context: any) => context.dataIndex === context.dataset.data.length - 1,
                align: 225,
                font: {
                    weight: "bold"
                }
            }
        }
    } as ChartOptions), [t]);

    return <>
        <Line options={options as any} data={data} />
        <div className="sr-only">
            <table>
                <caption>{t('elake-perhevapaan-ajalta-laskuri.chart.tableCaption')}</caption>
                <thead>
                    <tr>
                        <th>{t('elake-perhevapaan-ajalta-laskuri.chart.month')}</th>
                        <th>{t('elake-perhevapaan-ajalta-laskuri.chart.pensionFromParentalLeave')}</th>
                        <th>{t('elake-perhevapaan-ajalta-laskuri.chart.pensionFromSalary')}</th>
                    </tr>
                </thead>
                <tbody>
                    {getScreenreaderDatatableRows(data, t)}
                </tbody>
            </table>
        </div>
    </>
}

const getScreenreaderDatatableRows = (data: ChartData, translate: any) => {
    let result: JSX.Element[] = [];
    for (let i = 0; i < data.labels.length; i++) {
        result.push(<tr key={i}>
            <td>{`${i} ${translate('elake-perhevapaan-ajalta-laskuri.chart.month')}`}</td>
            <td>{translate('elake-perhevapaan-ajalta-laskuri.chart.datalabel', {euros: data.datasets[0].data[i]})}</td>
            <td>{translate('elake-perhevapaan-ajalta-laskuri.chart.datalabel', {euros: data.datasets[1].data[i]})}</td>
        </tr>);
    }
    return result;
}

export default ElakePerhevapaanAjaltaCalculator;
