export const toFiLocaleTwoDecimals = (n: number | undefined | null) => (n || n === 0) ? n.toLocaleString('fi', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
}) : n;

export const toFiLocaleNoDecimals = (n: number | undefined | null) => (n || n === 0) ? n.toLocaleString('fi', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
}) : n;

export const euroSum = (n: number | undefined | null) => n ? toFiLocaleNoDecimals(n) : "-";
export const euroSumOrZero = (n: number | undefined | null) => (n || n === 0) ? toFiLocaleNoDecimals(n) : "-";
