import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import NumberInput from "../NumberInput";
import ToggleInput from "../ToggleInput";
import ValidationContext, {Validation} from "../ValidationContext";
import ValidationFeedback from "../ValidationFeedback";
import {useValidation} from "../useValidation";
import {PerheElakeModel} from "../../types/PerheElakeModel";
import {calculate, PerheElakeResult} from "../../calculation/PerheElakeCalculation";
import CalculationResult from "../CalculationResult";
import {euroSumOrZero} from "../../utils/numberUtils";
import {Feedback, scrollTo} from "../../utils/form-util";
import {useCodeList} from "../useCodeList";
import CodesContext from "../CodesContext";
import {YearConstants, yearConstantValue} from "../../types/Codes";
import {useLocation} from "react-router-dom";
import {parseQuery} from "../../utils/queryUtils";
import {trackEvent} from "../../utils/piwik-util";

const PerheElakeCalculator: React.FC = () => {
    const yearConstants = useCodeList<YearConstants>("year-constants");
    return <CodesContext.Provider value={{yearConstants}}>
        <PerheElakeView/>
    </CodesContext.Provider>;
};

const PerheElakeView: React.FC =  () => {
    const {t} = useTranslation();
    const {yearConstants} = useContext(CodesContext);
    const [model, setModel] = useState<PerheElakeModel | null>(null);
    const [result, setResult] = useState<PerheElakeResult | null>(null);
    const [missingCodeErrors, setMissingCodeErrors] = useState<Feedback[]>([]);
    const resultsSection = useRef(null);
    const {search} = useLocation();
    const query = parseQuery(search);
    // Allow specifying year for e.g. repeatable Robot testing and manual testing:
    const year = parseInt(query.year) || new Date().getFullYear();
    const validation = useValidation();

    useEffect(() => {
        if (model) {
            let limit = yearConstantValue(year,
                'leskenElake', yearConstants);
            if (limit) {
                setResult(calculate(model,  limit));
                scrollTo(resultsSection);
            } else {
                setMissingCodeErrors([{text: t('errorFeedback.missingCodeValuesForThisYear')}]);
            }
        } else {
            setResult(null);
        }
    }, [model]);

    return <>
        <Container className="calculator-container content-container">
            <h1>{t('perhe-elakelaskuri.calculatorTitle')}</h1>
            <p className="ingress">
                {t('perhe-elakelaskuri.ingress')}
            </p>
        </Container>
        <div className="form-container py-4">
            <Container className="content-container">
                <Row>
                    <Col md={12}>
                        <PerheElakeForm onClear={() => setResult(null)}
                            onSubmit={(e, model) => {
                                setModel(model);
                            }} validation={validation} />
                    </Col>
                </Row>
                <Row>
                    <ValidationFeedback className={"mt-5"} titleKey={`errorFeedback.internalErrorTitle`} feedback={missingCodeErrors} />
                    {!missingCodeErrors.length && !validation.validationFeedback.length && <Col id="results-region" role="region" aria-live="polite" md={12} className="mt-4"
                         ref={resultsSection}>
                        {result && <div className="results-container">
                          <h2>{t('perhe-elakelaskuri.results.title')}</h2>
                            {result.widowPension !== undefined &&
                              <CalculationResult fieldName={"perhe-elakelaskuri.results.widowPension"}>
                                <>{euroSumOrZero(result.widowPension)}</>
                              </CalculationResult>}
                            {result.widowPensionReduced !== undefined &&
                              <CalculationResult fieldName={"perhe-elakelaskuri.results.widowPensionReduced"}>
                                <>{euroSumOrZero(result.widowPensionReduced)}</>
                              </CalculationResult>}
                            {result.childPension !== undefined &&
                              <CalculationResult fieldName={"perhe-elakelaskuri.results.childPension"}>
                                <>{euroSumOrZero(result.childPension)}</>
                              </CalculationResult>}
                            {result.familyPensionTotal !== undefined &&
                              <CalculationResult fieldName={"perhe-elakelaskuri.results.familyPensionTotal"}>
                                <>{euroSumOrZero(result.familyPensionTotal)}</>
                              </CalculationResult>}
                        </div>}
                    </Col>}
                </Row>
            </Container>
        </div>
    </>;
}


interface FormProps {
    onSubmit: (event: React.FormEvent<HTMLFormElement>, model: PerheElakeModel) => void
    onClear: () => void,
    validation: Validation
}

const PerheElakeForm: React.FC<FormProps> = ({onSubmit, onClear, validation}) => {
    const {t} = useTranslation();
    const topSection = useRef(null);

    const [formModel, setFormModel] = useState<PerheElakeModel>({
        beneficiaryWidow: false,
        beneficiaryChildren: false
    });
    const beneficiarySet = formModel.beneficiaryWidow || formModel.beneficiaryChildren;
    const childrenCountSet = formModel.underAgeChildrenCount ||
        formModel.adultChildrenCount ||
        formModel.childrenNotLivingWithLegatee;
    const requireWidowOwnPension =  formModel.beneficiaryWidow;
    const requireChildrenCount = formModel.beneficiaryChildren && !childrenCountSet;

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const validateResult = validation.validate(event) && beneficiarySet
            && (!requireChildrenCount || formModel.widowOwnPension)
            && (!requireChildrenCount || childrenCountSet);
        if (validateResult) {
            onSubmit(event, {...formModel});
        } else {
            onClear();
            scrollTo(topSection);
        }
    }

    return <ValidationContext.Provider value={validation}>
        <div ref={topSection}></div>
        <Form noValidate validated={validation.validated}
                onSubmit={handleSubmit} className="calculator-form" id={"perhe-elake"}>
            <ValidationFeedback feedback={validation.validationFeedback} />
            <Row>
                <Col md={12}>
                    <InputGroup hasValidation className={`${validation.validated ? "was-validated" : "was-not-validated"}`}>
                        <NumberInput fieldName={"perhe-elakelaskuri.legateePension"}
                                     initialValue={formModel.legateePension}
                                     min={0} max={999999999} onlyInts={true}
                                     required={true}
                                     onValidValueChange={legateePension => setFormModel(fm => ({...fm, legateePension}))}
                        />
                        <ToggleInput fieldName={"perhe-elakelaskuri.beneficiaryWidow"}
                                     initialValue={formModel.beneficiaryWidow}
                                     required={validation.validated && !beneficiarySet}
                                     onChange={beneficiaryWidow => setFormModel(fm => ({...fm, beneficiaryWidow}))}>
                            <NumberInput fieldName={"perhe-elakelaskuri.widowOwnPension"}
                                         initialValue={formModel.widowOwnPension}
                                         min={0} max={999999999} onlyInts={true}
                                         autoFocus={true}
                                         required={requireWidowOwnPension}
                                         onValidValueChange={widowOwnPension => setFormModel(fm => ({...fm, widowOwnPension}))}
                            />
                        </ToggleInput>
                        <ToggleInput fieldName={"perhe-elakelaskuri.beneficiaryChildren"}
                                     initialValue={formModel.beneficiaryChildren}
                                     required={validation.validated && !beneficiarySet}
                                     onChange={beneficiaryChildren => setFormModel(fm => ({...fm, beneficiaryChildren}))}>
                            <NumberInput fieldName={"perhe-elakelaskuri.underAgeChildrenCount"}
                                         initialValue={formModel.underAgeChildrenCount}
                                         min={requireChildrenCount ? 1 : 0} onlyInts={true}
                                         autoFocus={true}
                                         required={requireChildrenCount}
                                         onValidValueChange={underAgeChildrenCount => setFormModel(fm => ({...fm, underAgeChildrenCount}))}
                            />
                            <NumberInput fieldName={"perhe-elakelaskuri.adultChildrenCount"}
                                         initialValue={formModel.adultChildrenCount}
                                         min={requireChildrenCount ? 1 : 0} onlyInts={true}
                                         required={requireChildrenCount}
                                         onValidValueChange={adultChildrenCount => setFormModel(fm => ({...fm, adultChildrenCount}))}
                            />
                            <NumberInput fieldName={"perhe-elakelaskuri.childrenNotLivingWithLegatee"}
                                         initialValue={formModel.childrenNotLivingWithLegatee}
                                         min={requireChildrenCount ? 1 : 0} onlyInts={true}
                                         required={requireChildrenCount}
                                         onValidValueChange={childrenNotLivingWithLegatee => setFormModel(fm => ({...fm, childrenNotLivingWithLegatee}))}
                            />
                        </ToggleInput>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={6} className="mt-3">
                    <Button type="submit" aria-controls="results-region"
                            onClick={e => {
                                trackEvent('Button', t('perhe-elakelaskuri.calculate'));
                            }}>
                        {t('perhe-elakelaskuri.calculate')}
                    </Button>
                </Col>
            </Row>
        </Form>
    </ValidationContext.Provider>;
}

export default PerheElakeCalculator;
