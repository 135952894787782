import React from 'react';
import {createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider} from "react-router-dom";
import 'bootstrap/dist/js/bootstrap'
import {Container} from "react-bootstrap";
import YttariCalculator from "./components/yttari/YttariCalculator";
import {Header} from "./components/Header";
import Explanation from './components/Explanation';
import {useTranslation} from "react-i18next";
import Helmet from 'react-helmet';
import CalculatorSelector from "./components/laskurit/CalculatorSelector";
import PerheElakeCalculator from "./components/laskurit/PerheElakeCalculator";
import {hasLocale} from "./i18n";
import ElakePerhevapaanAjaltaCalculator from "./components/laskurit/ElakePerhevapaanAjaltaCalculator";
import {Chart as ChartJS, CategoryScale, Legend, LinearScale, LineElement, PointElement, Tooltip} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    ChartDataLabels
);

const withoutLanguage = (path: string) => {
    if (hasLocale(path)) {
        return path.substring(0, path.length - 3);
    }
    return path;
}

const App: React.FC = () => {
    const {t, i18n} = useTranslation();

    const pathname = window.location.pathname || '';
    const calculatorName = pathname === '/' || !withoutLanguage(pathname) ? 'selector' : withoutLanguage(pathname.substring(1));
    const i18nPrefix = calculatorName === 'yel-tyotulo-suositus-laskuri' ? 'yttari' : calculatorName;
    const pathPrefix = 'selector' !== i18nPrefix ? `${calculatorName}` : '';
    const pageTitle = i18nPrefix ? `${t(`${i18nPrefix}.pageTitle`)} - ${t('pageTitle')}` : t('pageTitle');
    const description = i18nPrefix ? t(`${i18nPrefix}.metaDescription`) : t('metaDescription')

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route element={<Body pathPrefix={pathPrefix}/>}>
                <Route path="/" element={<CalculatorSelector/>}/>
                <Route path="perhe-elakelaskuri/:locale" element={<PerheElakeCalculator/>}/>
                <Route path="perhe-elakelaskuri" element={<PerheElakeCalculator/>}/>
                <Route path="elake-perhevapaan-ajalta-laskuri/:locale" element={<ElakePerhevapaanAjaltaCalculator/>}/>
                <Route path="elake-perhevapaan-ajalta-laskuri" element={<ElakePerhevapaanAjaltaCalculator/>}/>
                <Route path="yel-tyotulo-suositus-laskuri/:locale" element={<YttariCalculator/>}/>
                <Route path="yel-tyotulo-suositus-laskuri" element={<YttariCalculator/>}/>
                <Route path=":locale" loader={({ params }) => {
                    // React router v6 only has exact matching or wildcard for routes and something like /no-such-thing
                    // would be matched by this and show wrong localizations
                    if (!['fi', 'sv', 'en'].includes(params.locale as string)) {
                        window.location.pathname = "/"
                    }
                    return true;
                }} element={<CalculatorSelector/>}/>
                <Route path='*' element={<Navigate to='/'/>}/>
            </Route>
        ), {basename: (window as any).API_PREFIX || ''}
    );

    return <>
        <Helmet>
            <html lang={i18n.language}/>
            <title>{pageTitle}</title>
            <meta name="description" content={description}/>
        </Helmet>
        <Container fluid className="main-container min-vh-100">
            <RouterProvider router={router}/>
            <Explanation i18nPrefix={i18nPrefix}/>
        </Container>
    </>;
};

interface Props {
    pathPrefix: string
}
const Body: React.FC<Props> = ({ pathPrefix }) => {
    const {t} = useTranslation();
    return <>
        <a className="skip-to-content sr-only sr-only-focusable" href="#main-content">{t('skipToContent')}</a>
        <Header pathPrefix={pathPrefix} />
        <main role="main" id="main-content" className="mt-4">
            <Outlet />
        </main>
    </>
}

export default App;
