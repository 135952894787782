import {Trans, useTranslation} from "react-i18next";
import React, {useState} from "react";
import {Accordion, Button, Card, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";

const Explanation: React.FC<{i18nPrefix: string}> = ({i18nPrefix}): JSX.Element => {
    const {t} = useTranslation();

    const isDefined = (key: string) => t(key) !== key;
    const isLocalized = (key: string) => isDefined(key) && t(key);

    return (
        <div className="explanation-container pb-4">
            <Container className="content-container">
                {isDefined(`${i18nPrefix}.aboutExplanation.title`) && <Row>
                    <Col className="pt-0 pb-4">
                        <CollapsibleAccordion titleKey={`${i18nPrefix}.aboutExplanation.title`}>
                            <Trans i18nKey={`${i18nPrefix}.aboutExplanation.body`}
                                   components={{
                                       ul: <ul></ul>,
                                       li: <li></li>,
                                       h2: <h2>heading2</h2>,
                                       Link1: <a href={t(`${i18nPrefix}.aboutExplanation.url1`)}>https://example.com/1</a>,
                                       Link2: <a href={t(`${i18nPrefix}.aboutExplanation.url2`)}>https://example.com/2</a>
                                   }}/>
                            {isLocalized(`${i18nPrefix}.aboutExplanation.readMore`) ? <p>
                                <Trans i18nKey={`${i18nPrefix}.aboutExplanation.readMore`}
                                       components={{
                                           Link: <a href={t(`${i18nPrefix}.aboutExplanation.readMoreUrl`)}>read more</a>
                                       }}
                                />
                            </p> : <></>}
                        </CollapsibleAccordion>
                    </Col>
                </Row>}
                {isDefined(`${i18nPrefix}.recommendationExplanation.title`) && <Row>
                    <Col className="py-1">
                        <CollapsibleAccordion titleKey={`${i18nPrefix}.recommendationExplanation.title`}>
                            <p>{t(`${i18nPrefix}.recommendationExplanation.paragraph1`)}</p>
                            {isLocalized(`${i18nPrefix}.recommendationExplanation.paragraph2`) ? <p>{t(`${i18nPrefix}.recommendationExplanation.paragraph2`)}</p> : <></>}
                            {isLocalized(`${i18nPrefix}.recommendationExplanation.readMore`) ? <p>
                                <Trans i18nKey={`${i18nPrefix}.recommendationExplanation.readMore`}
                                       components={{Link: <a href={t(`${i18nPrefix}.recommendationExplanation.readMoreUrl`)}>read more</a>}}
                                />
                            </p> : <></>}
                        </CollapsibleAccordion>
                    </Col>
                </Row>}
            </Container>
        </div>
    );
}

interface CollapsibleAccordionProps {
    titleKey: string,
    children: JSX.Element|JSX.Element[]|null
}

const CollapsibleAccordion = ({titleKey, children}: CollapsibleAccordionProps): JSX.Element => {
    const {t} = useTranslation();
    const [expanded, setExpanded] = useState<boolean>(false);

    const title = t(titleKey);
    const titleLocalized = !!title;
    if (!titleLocalized) {
        return <Card>
            <Card.Body>
                {children}
            </Card.Body>
        </Card>;
    }
    return <Accordion>
        <Card>
            <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0"
                                  onClick={() => setExpanded(!expanded)}
                                  aria-controls={`${titleKey}-container`}
                                  aria-expanded={expanded}>
                                            <span>
                                                {t(titleKey)}
                                            </span>
                    <FontAwesomeIcon className="icon"
                                     icon={expanded ? faChevronUp : faChevronDown}/>
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0" id={`${titleKey}-container`}  role="region">
                <Card.Body>
                    {children}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    </Accordion>;
};

export default Explanation;
