import React from "react";
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {changeLanguage} from "../i18n";

export const Header: React.FC<{pathPrefix: string}> = ({pathPrefix}) => {
    const {t} = useTranslation();
    return (
        <header className="page-header">
            <Container className="content-container">
                <Row>
                    <Col className="my-3 header-content">
                        <a href={t('pageTitleUrl')} className="header-title">{t("pageTitle")}</a>
                        <LanguageSelect pathPrefix={pathPrefix}/>
                    </Col>
                </Row>
            </Container>
        </header>
    );
};

const LanguageSelect: React.FC<{pathPrefix: string}> = ({pathPrefix}) => {
    const {t, i18n} = useTranslation();



    return (
        <ul className="language-select">
            {['fi', 'sv', 'en'].map(lang => (
                <li key={lang}>
                    <Link to={`${pathPrefix}/${lang}`} className={i18n.language === lang ? 'selected' : ''} onClick={() => changeLanguage(lang)}>
                        <span className="sr-only">{t(`languageSelect.${lang}.screenReaderText`)}</span>
                        <span>{t(`languageSelect.${lang}.abbreviation`)}</span>
                    </Link>
                </li>
            ))}
        </ul>
    );
};
