import React, {useContext, useEffect, useState} from "react";
import {Col, Form, FormLabel, InputGroup, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import ValidationContext from "./ValidationContext";
import HelpTextAccordion from "./HelpTextAccordion";

interface Props {
    fieldName: string,
    initialValue: undefined | null | string | number,
    onChange?: (value: undefined | string, valid : boolean) => void,
    onValidValueChange?: (value?: number) => void,
    placeholder?: string,
    min?: number,
    max?: number,
    required?: boolean,
    onlyInts?: boolean,
    autoFocus?: boolean
    size?: 'sm' | 'lg',
    style?: any
}

const NumberInput = React.forwardRef<HTMLInputElement, Props>(({
            fieldName,
            initialValue,
            onChange, onValidValueChange,
            min = Number.MIN_VALUE,
            max = Number.MAX_VALUE,
            onlyInts = false,
            required = false,
            size = undefined,
            placeholder = undefined,
            style = undefined,
            autoFocus
        }, forwardedRef) => {
    const {t} = useTranslation();
    const {validated, validationCounter} = useContext(ValidationContext);
    const unit = t(`${fieldName}.unit`) !== `${fieldName}.unit` ? t(`${fieldName}.unit`) : '';

    const [displayValue, setDisplayValue] = useState<undefined | string>(undefined);
    const [isValid, setValid] = useState<boolean>(true);
    const [hasBeenChanged, setHasBeenChanged] = useState(false);

    useEffect(() => {
        if (displayValue === undefined && initialValue) {
            setDisplayValue(`${initialValue}`);
            setValid(validate(`${initialValue}`));
        }
    }, [initialValue, displayValue]);

    useEffect(() => {
        validateAndUpdate(displayValue || "");
    }, [min, max, required, onlyInts]);

    const normalizeInputValue = (input: string) => {
        if (!input) {
            return input;
        }
        input = '' + input;
        if (input.indexOf(".") !== -1 && input.indexOf(",") !== -1) {
            // e.g. 1.234,56 => 1 234,56 or 2.000.000,00 becomes 2 000 000,00
            input = input.replaceAll(".", " ");
        }
        return input.replace("e", "").trim();
    }

    const cleanInput = (input: string) => {
        input = normalizeInputValue(input);
        return input ? input.replaceAll(",", ".").replaceAll(" ", "") : input;
    }

    const validate = (input: string): boolean => {
        if ((validationCounter === 0 || !required) && input === "") {
            return true;
        }

        input = normalizeInputValue(input);

        if (onlyInts) {
            const intRegex = /^[+-]?([0-9 ]*)$/;
            if (intRegex.test(input)) {
                const numericValue = parseInt(cleanInput(input));
                return numericValue >= min && numericValue <= max;
            }
            return false;
        }

        const floatRegex = /^[+-]?([0-9 ]*[,.])?[0-9 ]+$/;
        if (floatRegex.test(input)) {
            const numericValue = parseFloat(cleanInput(input));
            return numericValue >= min && numericValue <= max;
        }
        return false;
    }

    const validateAndUpdate = (value: string) => {
        if (validate(value)) {
            setDisplayValue(normalizeInputValue(value));
            setValid(true);
            if (onChange) {
                onChange(cleanInput(value), true);
            }
            if (onValidValueChange) {
                onValidValueChange(parseInt(cleanInput(value)))
            }
        } else {
            setDisplayValue(value);
            setValid(false);
            if (onChange) {
                onChange(undefined, false);
            }
            if (onValidValueChange) {
                onValidValueChange(undefined);
            }
        }
    }

    const hasTitle = t(`${fieldName}.title`) !== `${fieldName}.title`;

    const effectivePlaceholder = placeholder ? placeholder : required ? t('required') : undefined;

    return (
        <Form.Group className={`${(hasBeenChanged && !isValid) ? "is-invalid invalid" : ""}`}>
            <Row>
                <Col md={12}>
                    <FormLabel htmlFor={fieldName}>
                        {hasTitle ? <h2 className={"error-label"}>
                            {t(`${fieldName}.title`)}{required ? <span className="required">*</span> : ''}
                        </h2> : <></>}
                        {t(`${fieldName}.explanation`) !== `${fieldName}.explanation` ? <p>
                            {t(`${fieldName}.explanation`)}
                        </p> : <></>}
                        {t(`${fieldName}.label`) !== `${fieldName}.label` ? <span className={"error-label"}>{t(`${fieldName}.label`)}{required ? <span className="required">*</span> : ''}
                        </span> : <></>}
                    </FormLabel>
                    <HelpTextAccordion fieldName={fieldName} />
                </Col>
                <Col md={size === 'sm' ? 6 : 12}>
                    <InputGroup>
                    <Form.Control
                        id={fieldName}
                        data-testid={fieldName}
                        ref={forwardedRef}
                        size={size}
                        type="text"
                        style={style}
                        autoFocus={autoFocus}
                        className={`value-input ${(hasBeenChanged || validated) ? "changed" : ""} ${(hasBeenChanged && !isValid) ? "is-invalid invalid" : ""}`}
                        placeholder={effectivePlaceholder}
                        value={displayValue || ""}
                        onBlur={() => {
                            setHasBeenChanged(true);
                        }}
                        onChange={(e) => {
                            const value = e.currentTarget.value;
                            setHasBeenChanged(false);
                            validateAndUpdate(value);
                        }}
                        required={required}
                        isValid={(hasBeenChanged || validated) && isValid}
                        isInvalid={(hasBeenChanged || validated) && !isValid}>
                    </Form.Control>
                    {unit && <InputGroup.Append aria-hidden="true">
                        <InputGroup.Text>{unit}</InputGroup.Text>
                    </InputGroup.Append>}
                    <Form.Control.Feedback type="invalid">
                        {t(`${fieldName}.invalid`)}
                    </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
        </Form.Group>
    );
});

export default NumberInput;
