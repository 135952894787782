import {ElakePerhevapaanAjaltaModel} from "../types/ElakePerhevapaanAjaltaModel";
import {ElakePerhevapaanAjaltaResult} from "../types/ElakePerhevapaanAjaltaResult";
import {rangeClosed} from "../utils/array-utils";

export interface CalculationContext {
    readonly deductionCurrentYear: number // vakuutusmaksuvahennysKuluvavuosi
    readonly pensionReasonFactor: number // elakkeenPerusteKerroin
    readonly pensionReasonMinimumAllowance: number // elakkeenPerusteMinimipaivarahasta
    readonly accruePercentage: number // karttumisProsentti
    readonly lifetimeFactor: number // elinaikaKerroin
}

export interface ChartContext {
    translate: (translationKey: string) => string
}

export interface ChartData {
    datasets: {
        label: string
        data: number[]
        borderColor?: string
        fill?: boolean
        borderDash?: number[]
        pointBackgroundColor?: string
        lineTension?: number
    }[]
    labels: string[]
}

export function calculate(model: ElakePerhevapaanAjaltaModel, context: CalculationContext): ElakePerhevapaanAjaltaResult {
    let result = {} as ElakePerhevapaanAjaltaResult;

    result.pensionAllowance = calculatePensionFromAllowance(model.salaryPreviousYear || 0, model.allowanceDuration || 0, context)
    result.pensionHomeCare = calculatePensionFromHomeCare(model.homeCareDuration || 0, context)
    result.parentalPensionOverall = (result.pensionAllowance || 0) + (result.pensionHomeCare || 0);
    result.pensionSalary = calculatePensionFromPotentialSalary(model.salaryPreviousYear || 0, (model.allowanceDuration || 0) + (model.homeCareDuration || 0), context)

    return result;
}

export function calculateChartData(model: ElakePerhevapaanAjaltaModel, calculationContext: CalculationContext, chartContext: ChartContext): ChartData {
    let salaryPreviousYear = model.salaryPreviousYear || 0;
    let allowanceDuration = model.allowanceDuration || 0;
    let homeCareDuration = model.homeCareDuration || 0;

    let labels = rangeClosed(0, allowanceDuration + homeCareDuration).map(m => "" + m);
    let pensionFromParentalLeaveData: number[] = [];
    if (allowanceDuration) {
        pensionFromParentalLeaveData.push(...rangeClosed(0, allowanceDuration).map(m => calculatePensionFromAllowance(salaryPreviousYear, m, calculationContext)));
    }
    if (homeCareDuration) {
        let offset = pensionFromParentalLeaveData.length ? pensionFromParentalLeaveData[pensionFromParentalLeaveData.length - 1] : 0;
        pensionFromParentalLeaveData.push(...rangeClosed(pensionFromParentalLeaveData.length ? 1 : 0, homeCareDuration).map(m => offset + calculatePensionFromHomeCare(m, calculationContext)));
    }
    let pensionFromSalaryData = rangeClosed(0, allowanceDuration + homeCareDuration).map(m => calculatePensionFromPotentialSalary(salaryPreviousYear, m, calculationContext))

    return {
        datasets: [
            {
                label: chartContext.translate("elake-perhevapaan-ajalta-laskuri.chart.pensionFromParentalLeave"),
                data: pensionFromParentalLeaveData,
                borderColor: "#0071bc",
                fill: false,
                pointBackgroundColor: '#0071bc',
                lineTension: 0
            },
            {
                label: chartContext.translate("elake-perhevapaan-ajalta-laskuri.chart.pensionFromSalary"),
                data: pensionFromSalaryData,
                borderColor: "#fdb913",
                fill: false,
                borderDash: [10, 5],
                pointBackgroundColor: '#fdb913',
                lineTension: 0
            }
        ],
        labels
    };
}

function calculatePensionFromAllowance(salary: number, allowanceDuration: number, context: CalculationContext) {
    let result;
    if (salary * (1 - context.deductionCurrentYear) * context.pensionReasonFactor < context.pensionReasonMinimumAllowance) {
        result = context.pensionReasonMinimumAllowance * context.accruePercentage / 12 * allowanceDuration * context.lifetimeFactor;
    } else {
        result = salary * (1 - context.deductionCurrentYear) * context.pensionReasonFactor * context.accruePercentage / 12 * allowanceDuration * context.lifetimeFactor;
    }
    return Number.parseFloat(result.toFixed(1));
}

function calculatePensionFromHomeCare(homeCareDuration: number, context: CalculationContext) {
     let result = context.pensionReasonMinimumAllowance * context.accruePercentage / 12 * homeCareDuration * context.lifetimeFactor
    return Number.parseFloat(result.toFixed(1));
}

function calculatePensionFromPotentialSalary(salary: number, familyLeaveDuration: number, context: CalculationContext) {
    let result = salary * context.accruePercentage / 12 * familyLeaveDuration * context.lifetimeFactor;
    return Number.parseFloat(result.toFixed(2));
}
