import React from "react";
import {Feedback} from "../utils/form-util";

export interface Validation {
    validated: boolean
    validationCounter: number
    validationFeedback: Feedback[]
    validate(event: React.FormEvent<HTMLFormElement>): boolean
}

const ValidationContext = React.createContext<Validation>({
    validated: false,
    validationCounter: 0,
    validationFeedback: [],
    validate: () => false
});

export default ValidationContext;
