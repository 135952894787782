import React from "react";
import {ValueLabel} from "../types/ValueLabel";
import {I18n} from "../types/I18n";
import {PensionEstimate, YearConstants} from "../types/Codes";

interface ContextData {
    readonly industries?: ValueLabel<string, I18n>[]
    readonly yearConstants?: YearConstants[]
    readonly pensionEstimate?: PensionEstimate[]
}

const CodesContext = React.createContext<ContextData>({
    industries: [],
    yearConstants: [],
    pensionEstimate: []
});

export default CodesContext;
