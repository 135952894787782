import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Accordion, Button, Card} from "react-bootstrap";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
    fieldName: string
}

const HelpTextAccordion: React.FC<Props> = ({fieldName}) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const {t} = useTranslation();
    const key = fieldName + ".help";
    const titleKey = key + ".title";
    const textKey = key + ".text";
    const hasText = t(textKey) !== textKey;
    const hasTitle = t(titleKey) !== titleKey;

    const helpText = {
        title: hasTitle ? t(titleKey) : (t(`${fieldName}.title`) !== `${fieldName}.title` ? t(`${fieldName}.title`)
            : (t(`${fieldName}.label`) !== `${fieldName}.label` ? t(`${fieldName}.label`) : '')),
        text: hasText ? t(textKey) : ''
    };

    if (!hasText) {
        return null;
    }

    return (
        <Accordion className="help-text-container">
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0"
                                      onClick={() => setExpanded(!expanded)}
                                      aria-controls={`${titleKey}-container`}
                                      aria-expanded={expanded}>
                        {helpText.title}
                        <FontAwesomeIcon className="icon"
                                         icon={expanded ? faChevronUp : faChevronDown}/>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse id={`${titleKey}-container`} eventKey="0">
                    <Card.Body className="mt-2">
                        <Trans i18nKey={helpText.text}
                               components={{
                                   ul: <ul></ul>,
                                   li:  <li></li>,
                                   Link1: <a href={t(`${key}.url1`)}>https://example.com/1</a>,
                                   Link2: <a href={t(`${key}.url2`)}>https://example.com/2</a>
                               }}/>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

export default HelpTextAccordion;