import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {Button, Container} from "react-bootstrap";

const selectableCalculators = ['perhe-elakelaskuri', 'elake-perhevapaan-ajalta-laskuri', 'yel-tyotulo-suositus-laskuri'];
const CalculatorSelector: React.FC =  () => {
    const {t, i18n: {language}} = useTranslation();

    return <Container className="calculator-container content-container">
        <h1>{t('selector.calculatorTitle')}</h1>
        <p>
            <Trans i18nKey={'selector.ingress'}
                   components={{
                       Link1: <a href={t(`selector.url1`)}>https://example.com/1</a>
                   }}/>
        </p>
        {selectableCalculators.map(calculator =>
            <Button className={"w-100 btn-primary mb-3"} key={calculator} onClick={e => {
                e.preventDefault();
                window.location.pathname = `/${calculator}/${language}`;
            }}>{t(`${calculator}.name`)}</Button>)}
    </Container>
}

export default CalculatorSelector;
