import {GroupBase} from "react-select";
import {I18n} from "./I18n";

export interface ValueLabel<T, L = string> {
    readonly value: T,
    readonly label: L,
}

export interface Group<T> extends GroupBase<ValueLabel<T>>{
    readonly label: string,
    readonly options: readonly ValueLabel<T>[]
}

export function labels<T>(codes: ValueLabel<T, I18n>[], languageCode: string,
                       labelMapper: ((label: string, value: T) => string)): ValueLabel<T, string>[] {
    return codes
        .map(code => ({
                value: code.value,
                label: labelMapper(code.label[languageCode], code.value)}
        ));
}
