import React from "react";
import {Feedback} from "../utils/form-util";
import {Alert, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

export interface ValidationFeedbackProps {
    feedback: Feedback[],
    titleKey?: string
    className?: string
    ref?: any
}

const ValidationFeedback: React.FC<ValidationFeedbackProps> = ({feedback, titleKey, className, ref}) => {
    const {t} = useTranslation();

    return feedback.length ? <Row ref={ref} className={className}>
        <Col md={12}>
            <Alert variant="danger" className={"w-100"} autoFocus={true}>
                <h2>{t(titleKey || "errorFeedback.title")}</h2>
                <ul>
                    {feedback.map((fb, i) => <li key={`${fb.id}-${i}`} className={"invalid"}>
                        {fb.id && <a href={`#${fb.id}`}>
                          <strong>{fb.text}</strong>{fb.label ? ` (${fb.label})` : ''}
                        </a>}
                        {!fb.id && <>
                          <strong>{fb.text}</strong>{fb.label ? ` (${fb.label})` : ''}
                        </>}
                    </li>)}
                </ul>
            </Alert>
        </Col>
    </Row> : <></>;
};

export default ValidationFeedback;
