
export type CodeList = 'industry' | 'year-constants' | 'pension-estimate';

export interface YearConstants {
    leskenElake: number
    pieninMaksettavaKansanelake: number
    vahimmaisElakeKela: number
    elakkeenPerusteKerroin: number
    elakkeenPerusteMinimipaivarahasta: number
    paivitysVuosi: number
    karttumisProsentti: number
    rajatulo: number
    vakuutusmaksuvahennysKuluvavuosi: number
    taysiKansanElakeParisuhteessa: number
    taysiKansanElakeYksin: number
}

const _c = {} as YearConstants;
type YearConstant = keyof typeof _c;

export const yearConstantValue = (year: number, constant: YearConstant, yearConstants?: YearConstants[]): number|null => {
    if (!yearConstants) {
        return null;
    }
    let values = yearConstants.filter(yc => yc.paivitysVuosi === year);
    if (!values.length) {
        return null;
    }
    return values[0][constant];
}

export interface PensionEstimate {
    year: number
    birthYear: number
    EAK: number
    oldAgePensionAge: number
    targetAge: number
    lifeTimeExpectancyAtOldAgePensionAge: number
    lifeTimeExpectancyAtTargetAge: number
    sv: number
    ek: number
    alvev: number
    alvekk: number
    talvev: number
    talvekk: number
    partialOldAgePension: number
}

const _pe = {} as PensionEstimate
type PensionEstimateKey = keyof typeof _pe

export const pensionEstimateValue = (birthYear: number, estimate: PensionEstimateKey, values?: PensionEstimate[]): number|null => {
    if (!values) {
        return null;
    }
    let v = values.filter(pe => pe.birthYear === birthYear);
    if (!v.length) {
        return null;
    }
    return v[0][estimate];
}
