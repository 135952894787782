import axios from "axios";

export function setupAxios() {
  axios.defaults.withCredentials = true;
  // @ts-ignore
  axios.defaults.baseURL = window.API_PREFIX || '';

  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    let location = error.response?.data?.location;
    if (location) {
      // If error response body JSON has field location, redirect user:
      window.location.href = location;
    }
    return Promise.reject(error);
  });
}
