import {MutableRefObject} from "react";

export interface Feedback {
    id?: string
    text: string
    label?: string
}

export function resolveErrorsAsync(form: HTMLFormElement, setter: (values: Feedback[]) => void, includeLabel: boolean) {
    let formId = form.id;
    // reset
    setter([]);
    setTimeout(() => {
        let formToUse = form;
        if (formId) {
            formToUse = document.getElementById(formId) as HTMLFormElement;
        }
        setter(getInvalidFeedbacks(formToUse, includeLabel));
    }, 10);
}

export function getInvalidFeedbacks(form: HTMLFormElement, includeLabel: boolean): Feedback[] {
    let feedbacks: Feedback[] = [];
    form.querySelectorAll('.invalid-feedback').forEach(node => {
        if (getComputedStyle(node).display !== 'none') {
            let searchNode = node;
            let input = searchNode.closest('input');
            while (!input && searchNode.parentElement) {
                input = searchNode.parentElement.querySelector("input");
                searchNode = searchNode.parentElement;
            }
            const inputId = input?.id || input?.getAttribute("data-test-id") || undefined;
            if (input && inputId) {
                let labelElement = document.querySelector(`label[for='${input.id}']`);
                if (!labelElement) {
                    searchNode = input;
                    while (!labelElement && searchNode.parentElement) {
                        labelElement = searchNode.parentElement.querySelector("label");
                        searchNode = searchNode.parentElement;
                    }
                }
                if (labelElement && labelElement.className.indexOf('error-label') < 0) {
                    // if the label element isn't marked with error-label class, attempt to find first within
                    // the label element (this way if the label contains a lot of content, like explanations and
                    // links, they would not be included in field description as text content):
                    let errorLabelElement = labelElement.querySelector(".error-label");
                    if (errorLabelElement) {
                        labelElement = errorLabelElement;
                    }
                }
                let label = includeLabel ? labelElement?.textContent || undefined : undefined;
                if (label && label.endsWith('*')) {
                    label = label.substring(0, label.length-1).trim();
                }
                feedbacks.push({id: inputId, text: node.textContent || '', label});
            } else {
                feedbacks.push({id: '', text: node.textContent || ''});
            }
        }
    });
    return feedbacks;
}

export function scrollTo(ref: MutableRefObject<any>) {
    setTimeout(() => {
        (ref.current as any)?.scrollIntoView();
    }, 10);
}
