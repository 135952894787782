import React from "react";
import {useTranslation} from "react-i18next";
import HelpTextAccordion from "./HelpTextAccordion";

export interface ResultProps {
    fieldName: string,
    className?: string,
    children?: React.ReactElement | React.ReactElement[]
}
const CalculationResult: React.FC<ResultProps> = ({fieldName, className, children}) => {
    const {t} = useTranslation();

    if (!children) {
        return <></>;
    }

    const unit = t(`${fieldName}.unit`) !== `${fieldName}.unit` ? t(`${fieldName}.unit`) : '';
    return <div className={`result-section ${className ? className : ''}`} data-testid={`${fieldName}-section`}>
        <label className={"result-label"} htmlFor={fieldName} id={`${fieldName}-label`}>
            {t(`${fieldName}.label`)}
        </label>
        <HelpTextAccordion fieldName={fieldName} />
        <div className={"result-value"} data-testid={`${fieldName}`} id={fieldName} aria-labelledby={`${fieldName}-label`}>
            {children} {unit}
        </div>
    </div>
};

export default CalculationResult;
