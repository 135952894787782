import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import HttpApi from 'i18next-http-backend';

let language = 'fi';
export const hasLocale = (path: string) => {
    return path.match("^.*/(fi|en|sv)$");
}

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
    name: 'pathEnd',
    lookup: options => {
        const pathname = window.location.pathname;
        return hasLocale(pathname) ? pathname.substring(pathname.length - 2) : language;
    },
    cacheUserLanguage(lng, options) {
        language = lng;
    }
});

export const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
}

i18n
    .use(languageDetector)
    .use(HttpApi)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: '/i18n/{{lng}}.json',
        },
        supportedLngs: ["fi", "sv", "en"],
        // Not using fallback language because
        // a) easier to see which fields need localization or to
        // b) gaining the ability to hide fields conditionally if not implemented in a given language
        // c) when updating only one language at a time, it being Finnish, and it contains new fields
        // those fields would show up in Finnish until localized in other languages too (not probably wanted)
        //fallbackLng: "fi",
        whitelist: ["fi", "sv", "en"],
        detection: {
            order: ["path", "pathEnd"],
            lookupFromPathIndex: 0,
            checkWhiteList: true
        },
        interpolation: {
            escapeValue: false
        }
    });
