import {PerheElakeModel} from "../types/PerheElakeModel";

const CHILD_FACTOR_DIVISOR = 12;
const WIDOW_OWN_PENSION_CF = [6, 6, 5, 3, 2];
const CHILD_PENSION_WHEN_WIDOW_CF = [0, 4, 7, 9, 10];
const TOTALS_CF = WIDOW_OWN_PENSION_CF.map((v, i) => v + CHILD_PENSION_WHEN_WIDOW_CF[i]);
const CHILD_FACTORS = {
    widowOwnPension: WIDOW_OWN_PENSION_CF.map(v => v/CHILD_FACTOR_DIVISOR),
    childPensionWhenWidow: CHILD_PENSION_WHEN_WIDOW_CF.map(v => v/CHILD_FACTOR_DIVISOR),
    childPensionWhenNoWidow: TOTALS_CF.map(v => v/CHILD_FACTOR_DIVISOR)
}
type FactorType = keyof typeof CHILD_FACTORS;
const childFactor = (type: FactorType, childCount:number) => {
    let array = CHILD_FACTORS[type] || [0];
    return array[Math.min(childCount, array.length-1)];
}

let _formModelType = {} as PerheElakeModel;
type ChildProperty = ('adultChildrenCount' | 'underAgeChildrenCount' | 'childrenNotLivingWithLegatee') & keyof (typeof _formModelType);
const childCount = (model: PerheElakeModel,
                    childTypes: ChildProperty[] = ['adultChildrenCount', 'underAgeChildrenCount', 'childrenNotLivingWithLegatee']): number => {
    return model.beneficiaryChildren
        ? (childTypes.map(p => model[p])
            .reduce((a, b) => (a || 0) + (b || 0), 0) || 0)
        : 0;
}

export interface WidowPension {
    value: number
    reduced: boolean
}

export const widowPension = (model: PerheElakeModel, limit: number): WidowPension => {
    if (!model.beneficiaryWidow) {
        return {value: 0, reduced: false};
    }
    let legateePension = model.legateePension || 0;
    let widowPortion = childFactor('widowOwnPension', childCount(model));
    if (!model.widowOwnPension || model.widowOwnPension < limit
            || childCount(model, ['underAgeChildrenCount']) > 0) {
        return {reduced: false, value: parseFloat((legateePension * widowPortion).toFixed(2))};
    }
    return {reduced: true, value:
            parseFloat(Math.max(0, legateePension * widowPortion - 0.5 * (model.widowOwnPension - limit)).toFixed(2))};
}

export const childPensionPerChild = (model: PerheElakeModel): number => {
    let childrenCount = childCount(model);
    return parseFloat((childPensionTotal(model) / childrenCount).toFixed(2));
}

const childPensionTotal = (model: PerheElakeModel): number => {
    if (!model.beneficiaryChildren) {
        return 0;
    }
    let childrenCount = childCount(model);
    if (childrenCount <= 0) {
        return 0;
    }
    let legateePension = model.legateePension || 0;
    let childPortion = childFactor(
        model.beneficiaryWidow ? 'childPensionWhenWidow' : 'childPensionWhenNoWidow', childrenCount);
    return legateePension * childPortion;
}

export const familyTotal = (model: PerheElakeModel, limit: number): number => {
    let widow = widowPension(model, limit).value;
    let totalWith2DecimalsFromPerChildAmount = parseFloat((widow +
        parseFloat((childPensionPerChild(model) * childCount(model)).toFixed(2))).toFixed(2));
    let totalWithDirectChildTotal = parseFloat((widow +
        childPensionTotal(model)).toFixed(2));
    if (totalWithDirectChildTotal.toFixed(2) === (model.legateePension || 0).toFixed(2)) {
        // use the total that would possibly not match calculator roundings with 2 decimals if it would yield 100% of the legateePension:
        return totalWithDirectChildTotal;
    }
    return totalWith2DecimalsFromPerChildAmount;
}

export interface PerheElakeResult {
    widowPension?: number
    widowPensionReduced?: number
    childPension?: number
    familyPensionTotal?: number
}

export const calculate = (model: PerheElakeModel, limit: number): PerheElakeResult => {
    let result = {} as PerheElakeResult;
    if (model.beneficiaryWidow) {
        let widow = widowPension(model, limit);
        if (widow.reduced) {
            result.widowPensionReduced = widow.value;
        } else {
            result.widowPension = widow.value;
        }
    }
    if (model.beneficiaryChildren) {
        let child = childPensionPerChild(model);
        if (child) {
            result.childPension = child;
        }
        let total = familyTotal(model, limit);
        if (total) {
            result.familyPensionTotal = total;
        }
    }
    return result;
}
