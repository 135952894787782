import {useEffect, useState} from "react";
import axios from "axios";
import {CodeList} from "../types/Codes";

export function useCodeList<T>(codeList: CodeList): T[] {
    const [codes, setCodes] = useState<T[]>([]);

    useEffect(() => {
        axios.get<T[]>(`/api/code/${codeList}`, {responseType: "json"})
            .then(response => {
                if (response.data) {
                    setCodes(response.data);
                }
            });
    }, [codeList]);

    return codes;
}
