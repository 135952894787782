import React, {useMemo, useState} from "react";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import HelpTextAccordion from "./HelpTextAccordion";
import {useTranslation} from "react-i18next";
import Select, {createFilter} from "react-select";
import optimizedReactSelect from "./OptimizedReactSelect";

interface Props<ValueType> {
    fieldName: string
    required?: boolean
    className?: string
    placeholder?: string
    multi?: boolean
    options: readonly ({label: string, value: ValueType} | ValueType)[]
    disabled?: boolean
    isClearable?: boolean
}

export interface PropsSingle<ValueType> extends Props<ValueType> {
    multi?: false|undefined
    value: ValueType | undefined
    setValue: (value?: ValueType) => any
}

export interface PropsMulti<ValueType> extends Props<ValueType> {
    multi?: true
    value: ValueType[] | undefined
    setValue: (values: ValueType[]) => any
}

type SelectProps<ValueType> = PropsSingle<ValueType> | PropsMulti<ValueType>;

export default function SelectInput<Type>(props: SelectProps<Type>) {
    const {t} = useTranslation();

    const {fieldName, placeholder, required, className, multi, isClearable, options, value, setValue, disabled} = props

    const hasTitle = t(`${fieldName}.title`) !== `${fieldName}.title`;

    const valid = useMemo(() => !required || !!value, [required, value])

    const [input, setInput] = useState<string>('');
    const optionsWrapped = options.map((o:any) => o?.label ? o : ({label: o, value: o}));
    const selectedOptions = !value ? [] : (
        value instanceof Array ? optionsWrapped.filter(o => value.indexOf(o.value) !== -1)
            : optionsWrapped.filter(o => o.value === value));

    const effectivePlaceholder = placeholder
        || (t(`${fieldName}.placeholder`) !== `${fieldName}.placeholder` ? t(`${fieldName}.placeholder`) : undefined)
        || t(`defaultSelectPlaceholder`);

    return <Form.Group className={`select-input-group ${className ? className : ''}`}>
        <Row>
            <Col md={12}>
                <Form.Label htmlFor={fieldName}>
                    {hasTitle ? <h2 className={"error-label"}>
                        {t(`${fieldName}.title`)}{required ? <span className="required">*</span> : ''}
                    </h2> : <></>}
                    {t(`${fieldName}.explanation`) !== `${fieldName}.explanation` ? <p>
                        {t(`${fieldName}.explanation`)}
                    </p> : <></>}
                    {t(`${fieldName}.label`) !== `${fieldName}.label` ? <span className={"error-label"}>{t(`${fieldName}.label`)}{required ? <span className="required">*</span> : ''}
                        </span> : <></>}
                </Form.Label>
                <HelpTextAccordion fieldName={fieldName} />

                <Select inputId={fieldName} placeholder={effectivePlaceholder}
                        className={`select-input ${valid ? '' : 'invalid'}`}
                        classNamePrefix="select-input"
                        isMulti={multi}
                        isClearable={multi || isClearable}
                        options={optionsWrapped}
                        value={multi ? selectedOptions : selectedOptions.length ? selectedOptions[0] : undefined}
                        onChange={(newValue) => {
                            if (multi) {
                                setValue((newValue as any)?.map((o: any) => (o as any).value) || [])
                            } else {
                                setValue((newValue as any)?.value);
                            }
                        }}
                        onInputChange={setInput}
                        inputValue={input}
                        // performance issue resolving options:
                        captureMenuScroll={false}
                        components={optimizedReactSelect as any}
                        filterOption={createFilter({ignoreAccents: false, matchFrom: 'any'})} />

                <InputGroup hasValidation>
                    {/* Workaround to make react-select part of html5 validation */}
                    <Form.Control value={(Array.isArray(value) ? (value as any[]).join(", ") : (value as any)) || ""}
                                  data-test-id={fieldName}
                                  className="hidden"
                                  required={required}
                                  aria-hidden="true"
                                  onChange={() => {}}
                                  tabIndex={-1} />
                    <Form.Control.Feedback type="invalid">
                        {t(`${fieldName}.invalid`)}
                    </Form.Control.Feedback>
                </InputGroup>
            </Col>
        </Row>
    </Form.Group>
};